import React from "react";
import Layout from "../components/Common/Layout";
import SEO from "../components/Common/SEO";
import AccessApplication from "../components/AccessApplication";


const ErrorPage = () => (
  <Layout>
    <SEO />
    <AccessApplication />
  </Layout>
);

export default ErrorPage;
